define('byta/components/modal-contact-offers', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend({

		contactActive: true,

		activeTabClasses: 'byta-grey-text byta-bold-text byta-green-bg byta-text-subtitle',

		inactiveTabClasses: 'byta-green-text byta-grey-bg byta-text-subtitle',

		actions: {
			selectTab: function selectTab(selected) {
				var active = false;

				if (selected === 'contact') {
					active = true;
				}

				this.set('contactActive', active);
			}
		}
	});
});