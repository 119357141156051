define('byta/mixins/cookie-consent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      didTransition: function didTransition() {
        var ga = window['ga'];
        if (ga) {
          var page = this.get('page');
          var title = this.get('title');
          ga('send', 'pageview', { page: page, title: title });
        }
      }
    }
  });
});