/*import Ember from 'ember';

export default Ember.Component.extend({

  instance: null,

  init() {
		const content = this.get('content');

		this._super(...arguments);
  },

  didRender() {
    var elem = document.querySelector('.slider');

    this.set('instance', M.Slider.init(elem, {
      indicators: false
    }));

    var nameProject = this.get('nameProject');

  }

});*/
define("byta/components/modal-gallery", [], function () {
  "use strict";
});