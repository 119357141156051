define('byta/components/image-zoom', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    classNames: ['responsive-img', 'no-margin', 'no-padding'],
    tagName: 'img',

    attributeBindings: ['src'],

    materialboxedInstance: null,

    src: computed('projectId', 'photo', function () {
      var projectId = this.get('projectId');
      var photo = this.get('photo');

      return '/assets/img/project-' + projectId + '/' + photo;
    }),

    didInsertElement: function didInsertElement() {
      var self = this;
      var elem2 = self.$('.materialbox');

      self.set('materialboxedInstance', M.Materialbox.init(elem2, {
        originalWidth: '100%',
        originalHeight: '100%'
      }));
    }
  });
});