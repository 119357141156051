define('byta/mixins/click-else-where', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    onOutsideClick: Ember.K,

    handleOutsideClick: function handleOutsideClick(event) {
      var $element = this.$();
      var $target = $(event.target);
      if ($element) {
        if (!$target.parents($element).length) {
          console.log('click ouside component');
          this.onOutsideClick();
        } else {
          console.log('click inside component');
          //actions done by controller him self...
        }
      }
    },

    setupOutsideClickListener: Ember.on('didInsertElement', function () {
      var clickHandler = this.get('handleOutsideClick').bind(this);

      return Ember.$(document).on('click', clickHandler);
    }),

    removeOutsideClickListener: Ember.on('willDestroyElement', function () {
      var clickHandler = this.get('handleOutsideClick').bind(this);

      return Ember.$(document).off('click', clickHandler);
    })
  });
});