define('byta/routes/gallery', ['exports', 'byta/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    init: function init() {
      Ember.$(document).ready(function () {
        Ember.$('.modal').modal();
      });
    },
    model: function model() {
      return _constants.projects;
    }
  });
});