define('byta/router', ['exports', 'byta/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('welcome');
    this.route('home');
    this.route('staging');
    this.route('interiorism');
    this.route('services');
    this.route('gallery', function () {
      this.route('project', { path: 'project/:id' });
    });
    this.route('contact');
    this.route('privacy-policy');
  });

  exports.default = Router;
});