define('byta/components/carousel-news', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		/*
  didReceiveAttrs(){
  	Ember.$( document ).ready(function() {
  		let loop = setTimeout(autoplay, 3000);
  			Ember.$('.js-carousel-news').carousel({fullWidth: true}, loop);
  			function autoplay() {
  			Ember.$('.js-carousel-news').carousel('next');
  			loop = setTimeout(autoplay, 3000);
  		}
  	});
  },
  	didDestroyElement(){
  	Ember.$('.js-carousel-news').carousel('destroy');
  		// Ñapa para limpiar los intervalos y que no se vuelva loco el carrusel
  	for (let i = 1; i < 99999; i++) {
  		window.clearInterval(i);
  	}
  }
  */
	});
});