/*import Ember from 'ember';

export default Ember.Component.extend({

  instance: null,

  imageProject: '',

  nameProject: '',

  textProject: '',

  didReceiveAttrs() {
    var nameProject = this.get('nameProject');
    var textProject = this.get('textProject');
    var imageProject = this.get('imageProject');
    var route = this.get('nameProject');
    route = "/assets/img/" + route;
  }
});*/
define("byta/components/project-gallery", [], function () {
  "use strict";
});