define('byta/controllers/gallery/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    model: function model(_ref) {
      var title = _ref.title;

      return this.modelFor('gallery').findBy('title', title);
    }
  });
});