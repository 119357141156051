define('byta/components/carousel-staging', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didRender: function didRender() {
			Ember.$(document).ready(function () {
				Ember.$('.js-staging-carousel').carousel({ fullWidth: false });
			});
		},
		didDestroyElement: function didDestroyElement() {
			Ember.$('.js-staging-carousel').carousel('destroy');
		}
	});
});