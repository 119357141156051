define('byta/components/contact-floating', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend({

		instance: null,

		didRender: function didRender() {
			var elem = document.querySelector('.modal-contact-offers');

			this.set('instance', M.Modal.init(elem, {
				dismissible: true,
				preventScrolling: true
			}));
		}
	});
});