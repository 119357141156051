define('byta/components/side-menu', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend({

		instance: null,

		didInsertElement: function didInsertElement() {
			var elem = document.querySelector('.sidenav');

			this.set('instance', M.Sidenav.init(elem, {
				inDuration: 200,
				outDuration: 200,
				draggable: false
			}));
		},


		sideMenuExpanded: false,

		closeMenu: function closeMenu() {
			this.set('sideMenuExpanded', false);
		},


		actions: {
			goTo: function goTo(target) {
				this.closeMenus(); // cierro el menú
				this.transitionToRoute(target); // hago la transición
			}
		}
	});
});