define('byta/components/statistics-counter', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	var computed = Ember.computed;
	exports.default = Component.extend({

		text: '',

		safeText: computed('text', function () {
			return this.get('text').htmlSafe();
		}),

		counter: 0,

		max: 100,

		delay: 50,

		hasPercent: true,

		didRender: function didRender() {
			var _this = this;

			Ember.run.later(this, function () {
				_this.increment();
			}, this.get('delay'));
		},
		increment: function increment() {
			var counter = this.get('counter');

			if (this.get('max') > counter) {
				this.set('counter', counter + 1);
			}
		}
	});
});