define('byta/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = Ember.Route.extend({

    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);
        $(document).scrollTop(0);
      }
    }
    /*
      model() {
        Ember.run.later(this, '_chapu', 2000);
    	},
    
      _chapu() {
        window.cookieconsent.initialise({
          container: document.getElementById("content"),
          palette:{
            popup: {background: "#fff"},
            button: {background: "#aa0000"},
          },
          revokable:true,
          onStatusChange: function(status) {
            console.log(this.hasConsented() ?
              'enable cookies' : 'disable cookies');
          },
          law: {
            regionalLaw: false,
          },
          location: true,
        });
      }*/
  });
});