define('byta/routes/home', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		scrollSpyInstance: null,

		afterModel: function afterModel() {
			var elem = document.querySelector('.scrollspy');

			this.set('scrollSpyInstance', M.ScrollSpy.init(elem, {
				activeClass: 'green'
			}));
		}
	});
});